<template>
  <div class="admin-body">
    <div class="wrapper">
      <AdminSidebar :toggle="toggle" :isLayout="isLayout" />
      <div
        class="content h-100 w-100"
        :style="[isLayout === 'ideas' ? { backgroundColor: '#fff' } : '']"
      >
        <AdminHeader @toggle-sidebar="toggle = $event" />
        <main>
          <slot />
        </main>
      </div>
    </div>
    <transition name="fade">
      <Loading v-if="isLoading" @close="isLoading = $event" />
    </transition>
  </div>
</template>

<script>
import AdminHeader from "../components/Admin/AdminHeader.vue";
import AdminSidebar from "../components/Admin/AdminSidebar.vue";
import Loading from '../components/Modals/Loading.vue';
export default {
  components: { AdminSidebar, AdminHeader, Loading },
  name: "AdminLayout",
  data() {
    return {
      toggle: false,
    };
  },
  props: {
    isLayout: {
      type: String,
      default: "admin",
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["loading/getIsLoading"];
    },
  },
};
</script>

<style scoped>
@import "../assets/admin/styles/css/admin.css";
</style>
