<template>
  <nav
    id="content-navigation"
    class="navbar navbar-expand navbar-light bg-white pl-2 px-sm-5"
  >
    <button
      id="sidebar-toggle"
      class="btn-admin-nav d-flex mr-2 p-2"
      @click="toggleSidebar"
    >
      <i
        class="arrow-full-left align-self-center"
        :class="{ rotated: toggle }"
      ></i>
      <svg
        width="30px"
        height="24px"
        viewBox="0 4 30 22"
        style="fill: black; color: black"
      >
        <path
          stroke="rgba(0, 0, 0, 1)"
          stroke-width="4"
          stroke-miterlimit="10"
          d="M4 7h22M4 15h22M4 23h22"
        ></path>
      </svg>
    </button>

    <form class="form-inline d-none">
      <input
        class="form-control mr-sm-2"
        type="text"
        placeholder="Search projects..."
        aria-label="Search"
      />
    </form>

    <div class="navbar-collapse collapse">
      <!-- <ul class="nav navbar-nav ml-auto w-100 justify-content-end align-middle">
        <li
          class="nav-item dropdown align-self-center"
          :class="{ show: dropdownToglle }"
          @click="dropdownToglle = !dropdownToglle"
        >
          <a
            id="navbarDropdown"
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="dropdownToglle"
          >
            <span class="d-none d-sm-inline">demoinovasyon</span>&nbsp;&nbsp;<i
              class="user-image fallback-image"
              src="/Upload/DownloadFile?fileName=photo_f243b899-0fb6-4ce7-9611-f7c362ec4cdc.jpeg"
              alt="D"
              >D</i
            >
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            :class="{ show: dropdownToglle }"
            aria-labelledby="navbarDropdown"
          >
            <a class="dropdown-item" href="/user">Hesap Bilgileri</a>
            <a class="dropdown-item" href="/">Anasayfa</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="/Login/LogOut">Çıkış Yap</a>
          </div>
        </li>
      </ul> -->
      <b-dropdown
        class="nav navbar-nav ml-auto w-100 justify-content-end align-middle nav-item"
        id="navbarDropdown"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template slot="button-content">
          <div class="d-flex align-items-center">
            <span class="d-none d-sm-inline">{{ user.Data.FullName }}</span>
            <img
              v-if="user.Data.UserProfileImage"
              class="user-image fallback-image mx-2"
              :src="
                user.Data.UserProfileImage
                  ? `data:image/png;base64, ${user.Data.UserProfileImage}`
                  : ''
              "
              :alt="user.Data.FullName | getInitialsFilter"
            />
            <i
              v-else
              class="user-image fallback-image mx-2"
              :alt="user.Data.FullName | getInitialsFilter"
              >{{ user.Data.FullName | getInitialsFilter }}</i
            >
            <b-icon icon="caret-down-fill"></b-icon>
          </div>
        </template>
        <b-dropdown-item @click="$router.push('/user')"
          >Hesap Bilgileri</b-dropdown-item
        >
        <b-dropdown-item
          v-if="dynamicMenuUrl && dynamicMenuIsVisible"
          @click="$router.push(dynamicMenuUrl)"
          >{{ dynamicMenuName }}</b-dropdown-item
        >
        <b-dropdown-item @click="$router.push('/')">Anasayfa</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="logout">Çıkış Yap</b-dropdown-item>
      </b-dropdown>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { getInitials } from "../../helpers";
export default {
  name: "AdminHeader",
  data() {
    return {
      toggle: false,
      dropdownToglle: false,
      dynamicMenuUrl: "",
      dynamicMenuName: "",
      dynamicMenuIsVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  filters: {
    getInitialsFilter(value) {
      return getInitials(value);
    },
  },
  created() {
    if (this.$route.path.includes("/suggestion/idea")) {
      this.dynamicMenuUrl = "/admin/suggestions";
      this.dynamicMenuName = "Değerlendirme Ekranı";
      this.dynamicMenuIsVisible = this.user.Data.IsVisibleAdminPanel;
    } else {
      this.dynamicMenuUrl = "/suggestion/idea";
      this.dynamicMenuName = "Fikirlerim";
      this.dynamicMenuIsVisible = true;
    }
  },
  methods: {
    toggleSidebar() {
      this.toggle = !this.toggle;
      this.$emit("toggle-sidebar", this.toggle);
    },
    delete_cookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    async logout() {
      localStorage.clear();
      this.delete_cookie("token");
      await this.$msalInstance
        .logoutRedirect({})
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>

<style></style>
